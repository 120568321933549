import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { axios } from '../utils/axios';

import { find } from 'lodash';
import useUserStore from './userStore';

const store = (set, get) => ({
  businesses: [],

  currentBusiness: null,

  fetchBusiness: async (id) => {
    try {
      const response = await axios.get(`/businesses/${id}/`);

      set({ currentBusiness: response.data });

      return response;
    } catch (error) {
      console.error(error);
    }
  },

  fetchUserBusinesses: async () => {
    try {
      if (
        useUserStore.getState().user === null ||
        useUserStore.getState().appView !== 'OWNER'
      ) {
        return;
      }
      const response = await axios.get(`/user/businesses/`);

      set({ businesses: response.data });

      if (!get().currentBusiness) {
        set({
          currentBusiness:
            find(response.data, (b) => b.is_primary) || response.data[0],
        });
      }
      return response;
    } catch (error) {
      console.error(error);
    }
  },

  setCurrentBusiness: (business) => {
    set({ currentBusiness: business });
  },

  fetchBusinesses: async (args) => {
    try {
      const response = await axios.get('/businesses/', args);

      set({ businesses: response.data.results });

      return {
        count: response.data.count,
        total: response.data.count,
        results: get().businesses,
      };
    } catch (error) {
      console.error(error);
    }
  },
});

const useBusinessStore = create(
  devtools(store, {
    name: 'businessStore',
    enabled: process.env.NODE_ENV === 'development',
  }),
);

export default useBusinessStore;
