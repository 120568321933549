import { createContext, useState, useContext } from 'react';
import { Snackbar, Alert } from '@mui/material';

export const SnackbarContext = createContext();

export const SnackbarProvider = ({ children }) => {
  const [message, setMessage] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [severity, setSeverity] = useState('error');

  const showSnackbar = (message, severity = 'error') => {
    setMessage(message);
    setSeverity(severity);
    setSnackbarOpen(true);
  };

  const closeError = () => {
    setSnackbarOpen(false);
    setMessage(null);
  };

  return (
    <SnackbarContext.Provider value={{ showSnackbar, closeError }}>
      <Snackbar
        autoHideDuration={5 * 1000}
        open={snackbarOpen}
        onClose={closeError}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={closeError} severity={severity}>
          {message}
        </Alert>
      </Snackbar>

      {children}
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => {
  const context = useContext(SnackbarContext);
  if (context === undefined) {
    throw new Error('useSnackbar must be used within a SnackbarProvider');
  }
  return context;
};
