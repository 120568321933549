import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { axios, setAuthorization } from '../utils/axios';

const store = (set, get) => ({
  user: null,
  isUpdateUserSettingsLoading: false,
  appView: '',
  session: null,
  isAuthenticated: false,

  setUser: (userData) => set({ user: userData, appView: userData?.app_view }),

  updateUser: (data) => {
    const currentUser = get().user;
    set({ user: { ...currentUser, ...data } });
    set({ appView: data.app_view });
  },

  updateUserSettings: async (data) => {
    const { updateUser } = get();
    set({ isUpdateUserSettingsLoading: true });

    try {
      const response = await axios.patch(`/settings/${get().user.id}/`, data);
      if (response.status === 200) {
        updateUser(response.data);
      }
    } catch (error) {
      console.log('updateUserSettings error', error);
    } finally {
      set({ isUpdateUserSettingsLoading: false });
    }
  },

  refreshUser: async () => {
    try {
      const response = await axios.get(`/user/${get().user.id}/`);
      get().updateUser(response.data);
      return response.data;
    } catch (error) {
      console.log('refreshUser error', error);
      throw error;
    }
  },

  setIsUpdateUserSettingsLoading: (isLoading) => {
    set({ isUpdateUserSettingsLoading: isLoading });
  },

  setSessionData: (sessionData, status) => {
    set({
      session: sessionData,
      isAuthenticated: status ? status === 'authenticated' : false,
    });
    if (sessionData && sessionData?.user) {
      setAuthorization(sessionData.user.key);
    }
  },
});

const useUserStore = create(
  devtools(store, {
    name: 'userStore',
    enabled: process.env.NODE_ENV === 'development',
  }),
);

export default useUserStore;
