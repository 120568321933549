import axios from 'axios';
import _ from 'lodash';

/**
 * API
 *
 * Axios client with pre-configured headers.
 *
 */

const baseURL =
  typeof window === 'undefined'
    ? process.env.NEXT_PUBLIC_API_BASE_URL
    : process.env.NEXT_PUBLIC_API_BASE_URL_EXTERNAL;

const api = axios.create({
  baseURL: baseURL,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

let authInterceptors = [];

const setAuthorization = (token) => {
  clearAuthInterceptors();

  const id = api.interceptors.request.use((config) => {
    config.headers.Authorization = `Token ${token}`;
    return config;
  });

  authInterceptors.push(id);
};

const clearAuthInterceptors = () => {
  for (const id of authInterceptors) {
    axios.interceptors.request.eject(id);
  }
  authInterceptors = [];
  api.interceptors.request.clear();
};

const removeAuthorization = () => {
  api.interceptors.request.clear();
  clearAuthInterceptors();
};

export {
  api as axios,
  setAuthorization,
  removeAuthorization,
  authInterceptors,
};
